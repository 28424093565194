import React from 'react'
import PortableText from '../components/portableText'
import DropdownSelect from '../components/dropdownSelect'
import {isUserInRegion} from '../services/auth'
import { Player } from 'video-react'

const HomepagFeature = ({items, userRegion}) => {

    let filteredItem = items.filter(item => isUserInRegion(userRegion, item.region))

    return (
        <section className="bg-base pt-4 pb-0">
            <div className="container">
                {/* {console.log('---> items: ', items, filteredItem)} */}

                {
                    filteredItem &&
                    filteredItem.map((item, i) => {
                        let displayFile = item.files[0]
                        return (
                            <div key={i} className="col-sm-12 mb-30">
                                <div className={i%2 === 0 ? "bg-base row" : "bg-base row d-sm-flex flex-sm-row-reverse"}>
                                    <div className="col-sm-6">
                                        {
                                            (displayFile && displayFile._type === 'mainImage') &&
                                            <img className="card-img-top rounded" src={displayFile && displayFile.asset && (displayFile.asset.url)} alt={displayFile && displayFile.asset && (displayFile.asset.caption)} />
                                        }
                                        {
                                            (displayFile && displayFile._type === 'video') && 
                                            <div>
                                                <Player
                                                    poster={(displayFile.thumbnail && displayFile.thumbnail.asset) ? displayFile.thumbnail.asset.url : ''}
                                                >
                                                    <source src={(displayFile.file && displayFile.file.asset) ? displayFile.file.asset.url : ''} />
                                                </Player>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-sm-6">
                                        <h5 className={i%2 === 0 ? "card-title" : "card-title d-sm-flex flex-sm-row-reverse"}>{item.title}</h5>
                                        {item._rawDecription && <PortableText blocks={item._rawDecription} />}

                                        <DropdownSelect items={item.sectionLinks} userRegion={userRegion} />
                                    </div>

                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </section>
    )
}

export default HomepagFeature