import React, {useState, useEffect} from 'react'
import {graphql, Link, navigate, StaticQuery} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layoutSecure'
import Carousel from '../components/carousel'
import HomepagFeature from '../components/homepageFeature'
import HomepagSubFeature from '../components/homepageSubFeature'
import HomepageCommon from '../components/homepageCommon'
import PortableText from '../components/portableText'
import {isUserInRegion} from '../services/auth'
import { useSelector, useDispatch } from "react-redux"
import Bulletin from '../components/bulletin'

export const query = graphql`
    fragment SanityImage on SanityMainImage {
        crop {
            _key
            _type
            top
            bottom
            left
            right
        }
        hotspot {
            _key
            _type
            x
            y
            height
            width
        }
        asset {
            _id
        }
        caption
    }
    fragment SanityFile on SanityFileComponent {
        _key
        _type
        title
        region
        description
        file {
            asset {
                _id
                url
                mimeType
                originalFilename
            }
        }
    }
    fragment SanityLink on SanityLink {
        _key
        _type
        url
        title
        region
    }
    fragment SanityVideo on SanityVideo {
        _key
        _type
        thumbnail {
            alt
            asset {
                url
            }
        }
        title
        file {
            asset {
                url
                mimeType
                originalFilename
            }
        }
        region
    }
    fragment SanityCommonSection on SanityCommonSection {
        _id
        _key
        _type
        title
        links {
        ...SanityLink
        ...SanityFile
        ... on SanityCommonSubOrder {
            _key
            _type
            title
            links {
            ...SanityLink
            ...SanityFile
            }
            region
        }
        }
        region
    }
    fragment SanityVendorReference on SanityVendorReference {
        _key
        _type
        vendors {
            _type
            _key
            name
            services
            address
            city
            province
            contact
            email
            phoneNumber1
            phoneNumber2
            postalCode
            region
        }
    }



    query IndexPageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            keywords
        }

        homepage: sanityHomepage(_id: { regex: "/(drafts.|)homepage/" }) {
            carousel {
                banner {
                    asset {
                        url
                    }
                }
                _rawText(resolveReferences: {maxDepth: 5})
            }

            importantBulletins {
                importantBulletinsTitle
                _rawImportantBulletinsContent(resolveReferences: {maxDepth: 5})
                importantBulletinsURL
                region
            }

            categories {
                ... on SanityAccordion {
                _id
                title
                subSections {
                    ... on SanitySubSection {
                    title
                    sectionLinks {
                        ...SanityFile
                        ...SanityLink
                    }
                    region
                    files {
                        ... on SanityMainImage {
                        _key
                        _type
                        asset {
                            url
                            originalFilename
                        }
                        caption
                        }
                        ...SanityVideo
                    }
                    _id
                    _type
                    _rawDecription
                    }
                    ...SanityLink
                    ...SanityFile
                }
                region
                _rawDecription
                }
            }


        }

    }
`



const Homepage = props => {
    const {data, errors} = props

    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.userInfo)

    let userRegion = userInfo ? userInfo.region : ''

    if (errors) {
        return (
        <Layout>
            <GraphQLErrorList errors={errors} />
        </Layout>
        )
    }

    const site = (data || {}).site
    const homepage = (data || {}).homepage
    if (!site || !homepage) {
        throw new Error(
        'Missing "Site settings or Homepage" data. Open the studio at http://localhost:3333 and add some content to "Site settings or Homepage" and restart the development server.'
        )
    }

    console.log('bulletins : ', homepage.importantBulletins)

    return (
        <Layout>
            <SEO
                title={site.title}
                description={site.description}
                keywords={site.keywords}
            />

            {/* carousel */}
            <div className='container container-adjust-p'>
            {homepage && <Carousel carousel={homepage.carousel} />}
            </div>

            {/* importantBulletins */}
            {
                (homepage.importantBulletins && homepage.importantBulletins?.length > 0) && (
                    <div className='bulletin-wrapper'>
                        {homepage.importantBulletins.map((bulletin, i) => {
                            if (!isUserInRegion(userRegion, bulletin.region)) return
                            return <Bulletin key={i} bulletin={bulletin} />
                        })}
                    </div>
                )
            }

            {/* categories */}
            {
                homepage.categories &&
                (homepage.categories.map((categorie, i) => {
                    
                    if (!isUserInRegion(userRegion, categorie.region)) return

                    if (i === 0) {
                        return <HomepagFeature items={categorie.subSections} userRegion={userRegion} key={i} />
                    }
                    if (i === 1) {
                        return <HomepagSubFeature items={categorie.subSections} userRegion={userRegion} key={i} />
                    }
                    if (i === 2) {
                        return <HomepageCommon key={i} items={categorie} userRegion={userRegion} />
                    }
                
                }))
            }   
        </Layout>
    )
}

export default Homepage