import React from 'react'
import PortableText from '../components/portableText'
import {Link} from 'gatsby'
import DropdownSelect from '../components/dropdownSelect'
import {isUserInRegion} from '../services/auth'

const HomepagSubFeature = ({items, userRegion}) => {

    let filteredItem = items.filter(item => isUserInRegion(userRegion, item.region))

    return (
        <section className="bg-base pt-0 pb-0">
            <div className="container">
                <div className="row">
                    {
                        filteredItem &&
                        filteredItem.map((item, i) => (
                            <div key={i} className="col-sm-6 col-lg-3 mb-30">
                                <div className="bg-base card">
                                    <h5 className="card-title">{item.title}</h5>
                                    <div className="position-relative">
                                        {
                                            item.files[0] ?                                
                                            <img className="card-img-top rounded" src={item.files[0].asset.url} alt={item.files[0].asset.caption} />
                                            : <img className="card-img-top rounded" src='https://cdn.sanity.io/files/5t8iwfwq/production/7faed1104ff0351bb6483fd4330861204a8dd3ca.jpg' alt="card image" />
                                        }
                                    </div>                
                                    <div className="bg-base card-body px-0 position-relative zindex-9">
                                    <span className="small">{item._rawDecription && <PortableText className="small" blocks={item._rawDecription} />}</span>

                                    {
                                        (() => {
                                            if(item.sectionLinks.length === 1){
                                                return <Link to={item.sectionLinks[0].url || '#'} className="btn btn-primary" style={{float: 'right'}}>GO</Link>
                                            }
                                            else if(item.sectionLinks.length > 1){
                                                return <DropdownSelect items={item.sectionLinks} userRegion={userRegion} />
                                            }
                                        })()
                                    }

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default HomepagSubFeature