import React, {useState, useEffect} from 'react'
import PortableText from './portableText'

const Bulletin = ({bulletin}) => {
    return(
        <div className='container container-adjust-p bg-light p-4'>
            { bulletin.importantBulletinsTitle && <h3>{ bulletin.importantBulletinsTitle }</h3> }
            { bulletin._rawImportantBulletinsContent && <PortableText blocks={ bulletin._rawImportantBulletinsContent } /> }
            { bulletin.importantBulletinsURL && <a href={ bulletin.importantBulletinsURL } title={ bulletin.importantBulletinsURL } target="_blank" rel="nofollow" className="btn btn-primary">Click Here</a> }
        </div>
    )
}

export default Bulletin