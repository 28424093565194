import React from 'react'
import AccordionLinkFile from '../components/accordion-link-file'

const HomepageCommon = ({items, userRegion}) => (
    <section className="bg-base pt-0">
        <div className="container">
            <div className="row">

                {/* {console.log('---> items: ', items)} */}


                <div className="accordion accordion-primary" id="accordionCommon">

                    <div className="accordion-item">
                        <h6 className="accordion-header" id="heading-1">
                            <button className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                            {items.title}
                            </button>
                        </h6>
                        <div id="collapse-1" className="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionCommon">
                            <div className="accordion-body">
                                <AccordionLinkFile userRegion={userRegion} items={items.subSections.filter(item => item._type === 'link' || item._type === 'fileComponent')} />
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </section>
)

export default HomepageCommon